@import 'amway-icons';
@import 'amway-icons-solid';

@font-face {
  font-family: 'AmwayIconsBc';
  src:
    url('../fonts/amway-icons/AmwayIcons.ttf?w7ri02') format('truetype'),
    url('../fonts/amway-icons/AmwayIcons.woff?w7ri02') format('woff'),
    url('../fonts/amway-icons/AmwayIcons.svg?w7ri02#AmwayIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="amw-icon-"], [class*=" amw-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AmwayIconsBc' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.amw-icon-message-notification-bell:before {
  content: "\e900";
}
.amw-icon-contract:before {
  content: "\e92b";
  color: #fff;
}
.amw-icon-first-year:before {
  content: "\e92c";
  color: #fff;
}
.amw-icon-file-invoice:before {
  content: "\e92d";
}
.amw-icon-bi-direct-arrow:before {
  content: "\e92e";
}
.amw-icon-house:before {
  content: "\e92f";
}
.amw-icon-cc-union-pay:before {
  content: "\e930";
}
.amw-icon-cc-amex:before {
  content: "\e931";
}
.amw-icon-cc-diners-club:before {
  content: "\e932";
}
.amw-icon-cc-discover:before {
  content: "\e933";
}
.amw-icon-cc-jcb:before {
  content: "\e934";
}
.amw-icon-cc-mastercard:before {
  content: "\e935";
}
.amw-icon-cc-visa:before {
  content: "\e936";
}
.amw-icon-dollar-sign-light:before {
  content: "\e937";
}
.amw-icon-business-time:before {
  content: "\e938";
}
.amw-icon-heart-circle:before {
  content: "\e939";
}
.amw-icon-shield-alt:before {
  content: "\e93a";
}
.amw-icon-file-contract:before {
  content: "\e93b";
}
.amw-icon-store:before {
  content: "\e93c";
}
.amw-icon-history:before {
  content: "\e93d";
}
.amw-icon-credit-card:before {
  content: "\e93e";
}
.amw-icon-user-light:before {
  content: "\e93f";
}
.amw-icon-bell:before {
  content: "\e940";
}
.amw-icon-user-clock:before {
  content: "\e941";
}
.amw-icon-check-thin:before {
  content: "\e942";
}
.amw-icon-hand-holding-heart:before {
  content: "\e943";
}
.amw-icon-lightbulb-on:before {
  content: "\e944";
}
.amw-icon-trash:before {
  content: "\e945";
}
.amw-icon-box-heart:before {
  content: "\e946";
}
.amw-icon-exchange:before {
  content: "\e947";
}
.amw-icon-user-clock_duotone .path1:before {
  content: "\e948";
  color: rgb(44, 44, 44);
}
.amw-icon-user-clock_duotone .path2:before {
  content: "\e949";
  margin-left: -1.25em;
  color: rgb(56, 83, 154);
}
.amw-icon-check-circle_duotone .path1:before {
  content: "\e94a";
  color: rgb(44, 44, 44);
}
.amw-icon-check-circle_duotone .path2:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(17, 135, 76);
}
.amw-icon-AmPerks_Heart-2:before {
  content: "\e98d";
  color: #37529a;
}
.amw-icon-dash-line:before {
  content: "\e9b1";
}
.amw-icon-circle-segments:before {
  content: "\e9b3";
}
.amw-icon-downleg:before {
  content: "\e9bf";
}
.amw-icon-tile_view:before {
  content: "\e9d0";
}
.amw-icon-clipboard-check:before {
  content: "\e9d1";
}
.amw-icon-chart-pie:before {
  content: "\e9d2";
}
.amw-icon-cut:before {
  content: "\e9d3";
}
.amw-icon-ellipsis-h:before {
  content: "\e9d4";
}
.amw-icon-angle-double-up:before {
  content: "\e9d5";
}
.amw-icon-angle-double-down:before {
  content: "\e9d6";
}
.amw-icon-warning:before {
  content: "\e9d7";
}
.amw-icon-phone:before {
  content: "\e9d8";
}
.amw-icon-rocket-launch:before {
  content: "\e9d9";
}
.amw-icon-comment-lines:before {
  content: "\e9da";
}
.amw-icon-caret-up:before {
  content: "\e9db";
}
.amw-icon-caret-down:before {
  content: "\e9dc";
}
.amw-icon-file-pdf:before {
  content: "\e9dd";
}
.amw-icon-play-circle:before {
  content: "\e9de";
}
.amw-icon-check-square:before {
  content: "\e9df";
}
.amw-icon-minus-circle:before {
  content: "\e9e0";
}
.amw-icon-link:before {
  content: "\e9e1";
}
.amw-icon-th-list:before {
  content: "\e9e2";
}
.amw-icon-search-minus:before {
  content: "\e9e3";
}
.amw-icon-search-plus:before {
  content: "\e9e4";
}
.amw-icon-users:before {
  content: "\e9e5";
}
.amw-icon-shield:before {
  content: "\e9e6";
}
.amw-icon-share:before {
  content: "\e9e7";
}
.amw-icon-search:before {
  content: "\e9e8";
}
.amw-icon-expand-alt:before {
  content: "\e9e9";
}
.amw-icon-undo:before {
  content: "\e9ea";
}
.amw-icon-user-solid:before {
  content: "\e9eb";
}
.amw-icon-registered:before {
  content: "\e9ec";
}
.amw-icon-shopping-cart:before {
  content: "\e9ed";
}
.amw-icon-print:before {
  content: "\e9ee";
}
.amw-icon-plus:before {
  content: "\e9ef";
}
.amw-icon-comment:before {
  content: "\e9f0";
}
.amw-icon-percent:before {
  content: "\e9f1";
}
.amw-icon-pencil:before {
  content: "\e9f2";
}
.amw-icon-newspaper:before {
  content: "\e9f3";
}
.amw-icon-edit:before {
  content: "\e9f4";
}
.amw-icon-minus:before {
  content: "\e9f5";
}
.amw-icon-mail:before {
  content: "\e9f6";
}
.amw-icon-sign-out:before {
  content: "\e9f7";
}
.amw-icon-lock:before {
  content: "\e9f8";
}
.amw-icon-book-open:before {
  content: "\e9f9";
}
.amw-icon-leaf:before {
  content: "\e9fa";
}
.amw-icon-info-circle:before {
  content: "\e9fb";
}
.amw-icon-list:before {
  content: "\e9fc";
}
.amw-icon-home2:before {
  content: "\e9fd";
}
.amw-icon-question-circle-solid:before {
  content: "\e9fe";
}
.amw-icon-question-circle:before {
  content: "\e9ff";
}
.amw-icon-angle-double-left:before {
  content: "\ea00";
}
.amw-icon-exclamation:before {
  content: "\ea01";
}
.amw-icon-cog:before {
  content: "\ea02";
}
.amw-icon-hdd:before {
  content: "\ea03";
}
.amw-icon-file-alt:before {
  content: "\ea04";
}
.amw-icon-hourglass:before {
  content: "\ea05";
}
.amw-icon-sync:before {
  content: "\ea06";
}
.amw-icon-user:before {
  content: "\ea07";
}
.amw-icon-dollar-sign:before {
  content: "\ea08";
}
.amw-icon-file-signature:before {
  content: "\ea09";
}
.amw-icon-chart-bar:before {
  content: "\ea0a";
}
.amw-icon-angle-double-right:before {
  content: "\ea0b";
}
.amw-icon-cross:before {
  content: "\ea0c";
}
.amw-icon-times-circle:before {
  content: "\ea0d";
}
.amw-icon-angle-left:before {
  content: "\ea0e";
}
.amw-icon-angle-right:before {
  content: "\ea0f";
}
.amw-icon-check:before {
  content: "\ea10";
}
.amw-icon-check-circle:before {
  content: "\ea11";
}
.amw-icon-calendar:before {
  content: "\ea12";
}
.amw-icon-calculator:before {
  content: "\ea13";
}
.amw-icon-browser:before {
  content: "\ea14";
}
.amw-icon-arrow-with-circle-up:before {
  content: "\ea15";
}
.amw-icon-angle-down:before {
  content: "\ea16";
}
.amw-icon-angle-up:before {
  content: "\ea17";
}
.amw-icon-archive:before {
  content: "\ea18";
}
.amw-icon-heart:before {
  content: "\ea19";
}
.amw-icon-exclamation-circle:before {
  content: "\ea1a";
}
.amw-icon-plus-circle:before {
  content: "\ea1b";
}
.amw-icon-bars-plus:before {
  content: "\ea1c";
}
.amw-icon-block:before {
  content: "\ea1d";
}
.amw-icon-amway-shield:before {
  content: "\ea1e";
}
.amw-icon-menu:before {
  content: "\ea1f";
}
.amw-icon-ditto:before {
  content: "\ea20";
}
.amw-icon-new-site .path1:before {
  content: "\ea21";
  color: rgb(1, 1, 1);
}
.amw-icon-new-site .path2:before {
  content: "\ea22";
  margin-left: -0.7724609375em;
  color: rgb(3, 4, 4);
}
.amw-icon-rearrange:before {
  content: "\ea23";
}
.amw-icon-search-results:before {
  content: "\ea24";
}
.amw-icon-youtube:before {
  content: "\e94c";
}
.amw-icon-linkedin:before {
  content: "\e94d";
}
.amw-icon-twitter:before {
  content: "\e94e";
}
.amw-icon-instagram:before {
  content: "\e94f";
}
.amw-icon-facebook-f:before {
  content: "\e950";
}
.amw-icon-facebook:before {
  content: "\e951";
}
.amw-icon-external-link-square:before {
  content: "\e952";
}
.amw-icon-exclamation-square:before {
  content: "\e953";
}
.amw-icon-warning-light:before {
  content: "\e954";
}
.amw-icon-lightning:before {
  content: "\e955";
  color: #38539a;
}
.amw-icon-down-arrow:before {
  content: "\e956";
}
